import { CacheItemType } from "./lib/models";
import cfg from "./buildConfig";
import { debugMessage } from "./lib/pluginUtil";

interface CacheItem {
  resolved?: boolean;
  promise?: Promise<any>;
  result?: any;
}

export class ApiCache {
  cacheItemType: CacheItemType;
  cache: Record<string, CacheItem>;
  templateHtml: Record<string, any>;

  constructor(cacheItemType: CacheItemType) {
    this.cacheItemType = cacheItemType;
    this.cache = {};
    this.templateHtml = {};
  }

  async initCacheForService(itemsList: string[], url: string) {
    const existingPromises = this.getPromisesFor(itemsList);
    const itemsToFetch = this.getItemsToFetch(itemsList);
    const promise = this.prepareRequestPromise(url, itemsToFetch);
    this.setPromise(itemsToFetch, promise);
    await Promise.all(existingPromises.concat(promise));
  }

  private getItemsToFetch(itemsList: string[]) {
    return itemsList.filter((item) => !this.cache[item]?.resolved && !this.cache[item]?.promise);
  }

  private cacheResponseData(responseData, location?, templateHtml?) {
    responseData.forEach((result) => {
      const index = this.cacheItemType === CacheItemType.STOCK_NUMBER_VIN ? result.stockNumber : result.vin;
      this.cache[index].result = result;
    });
    if (templateHtml) {
      this.templateHtml[location] = templateHtml;
    }
  }

  private clearItemsFromCache(items: string[]) {
    items.forEach((item) => {
      delete this.cache[item];
    });
  }

  private clearPromise(items: string[]) {
    items.forEach((item) => {
      this.cache[item].resolved = true;
      delete this.cache[item].promise;
    });
  }

  private setPromise(items: string[], promise: Promise<unknown>) {
    items.forEach((item) => {
      this.cache[item] = {
        promise,
        resolved: false,
      };
    });
  }

  private getPromisesFor(items: string[]) {
    return items.reduce((acc: any[], item: string) => {
      if (this.cache[item]?.promise) {
        acc.push(this.cache[item]?.promise);
      }
      return acc;
    }, []);
  }

  private async prepareRequestPromise(url: string, itemsList: string[], location?: string) {
    if (!itemsList.length) {
      return Promise.resolve();
    }
    const payload =
      this.cacheItemType !== CacheItemType.STOCK_NUMBER_VIN
        ? itemsList
        : {
            snap_dealer_id: cfg.dealer_id,
            autoapr_dealer_id: cfg.autoapr_dealer_id,
            stock_numbers: itemsList,
          };
    return await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`Error fetching requested ${this.cacheItemType} data: ${itemsList}`);
        }
        const data = await response.json();
        this.clearPromise(itemsList);
        this.cacheResponseData(this.processPromiseData(data, itemsList), location, data.template_html);
      })
      .catch((error) => {
        console.error("TradePending: getVinsFromStockNumbersOrVehiclesConditionPromise ajax error:", error);
        this.clearItemsFromCache(itemsList);
      });
  }

  private processPromiseData(data: any, itemsList: string[]) {
    let processedData;
    if (this.cacheItemType === CacheItemType.SUPERLATIVE) {
      const superlatives = data.result || data;
      const authorized = data.authorized;
      debugMessage("fetch_superlatives result data: ", data);
      const no_null_data = superlatives.filter(function (superlative_info) {
        return !!superlative_info;
      });
      processedData = authorized
        ? itemsList.map(function (vin) {
            const superlative = no_null_data.find(function (superlative_info) {
              return superlative_info.vin === vin;
            });
            if (superlative) {
              return superlative;
            } else {
              return {
                vin: vin,
                placeholder: true,
              };
            }
          })
        : no_null_data;
    } else {
      processedData = Object.keys(data).reduce((acc: any[], item) => {
        const index = this.cacheItemType === CacheItemType.VEHICLE_CONDITION ? "vin" : "stockNumber";
        acc.push({ [index]: item, ...data[item] });
        return acc;
      }, []);
    }
    return processedData;
  }
}
