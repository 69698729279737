export const debugMessage = process.env.DEBUG_ENABLED ? console.log.bind(console) : () => {};

export function getCookie(name: string) {
  const matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function isMobile() {
  return window.matchMedia("only screen and (max-width: 480px)").matches;
}

export function shouldInstallWidgetOnDevice(disableOnMobile: boolean, disableOnDesktop: boolean) {
  const is_mobile = isMobile();
  if ((is_mobile && !disableOnMobile) || (!is_mobile && !disableOnDesktop)) {
    return true;
  }
  return false;
}
